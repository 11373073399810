import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { ADDRESS_BASE_FRAGMENT } from '@/graphql/_Fragments/Address/Base';
import { COUNTRY_BASE_FRAGMENT } from '@/graphql/_Fragments/Country/Base';
import {
  COMMUNITY_USER_INTEREST_BASE_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserInterest/Base';
import {
  COMMUNITY_USER_CONNECTION_FULL_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserConnection/Full';
import {
  COMMUNITY_USER_PROFILE_ROLE_FULL_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserProfileRole/Full';
import { COMPANY_USER_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/CompanyUserRole/Full';
import { SUB_EDITION_USER_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/SubEditionUserRole/Full';
import { SPEAKER_FULL_FRAGMENT } from '@/graphql/_Fragments/Speaker/Full';
import { COMMUNITY_USER_TAG_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUserTag/Base';
import { COMMUNITY_USER_URL_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUserUrl/Base';
import {
  COMMUNITY_USER_PREFERENCES_BASE_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserPreferences/Base';

export const COMMUNITY_USER_FULL_FRAGMENT = `
  fragment communityUserFullFragment on CommunityUser {
    uid
    schemaCode
    firstName
    lastName
    name
    suffix
    prefix
    email
    jobTitle
    employerName
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    bio
    profilePrivacyMode
    address {
      ...addressBaseFragment
    }
    bookmarks {
      uid
      link {
        uid
        target {
          __typename
          uid
        }
      }
    }
    country {
      ...countryBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    interests {
      ...communityUserInterestBaseFragment
    }
    connections {
      ...communityUserConnectionFullFragment
    }
    profileRoles {
      ...communityUserProfileRoleFullFragment
    }
    companyRoles(filter: {state: "ACCEPTED"}) {
      ...companyUserRoleFullFragment
    }
    subEditionRoles {
      ...subEditionUserRoleFullFragment
    }
    speakers {
      ...speakerFullFragment
    }
    urls {
      ...communityUserUrlBaseFragment
    }
    tags {
      ...communityUserTagBaseFragment
    }
    follows {
      uid
      __typename
      link {
        __typename
        ... on CommunityUserLink {
          uid
          target {
            __typename
            uid
          }
        }
      }
    }
    blockedUsers {
      uid
      name
    }
    blockedByUsers {
      uid
      name
    }
    preferences: _preferences {
      ...communityUserPreferencesBaseFragment
    }
    _followerCount
    _connectionCount
  }
  ${COMMUNITY_USER_PROFILE_ROLE_FULL_FRAGMENT}
  ${COMMUNITY_USER_PREFERENCES_BASE_FRAGMENT}
  ${COMMUNITY_USER_CONNECTION_FULL_FRAGMENT}
  ${COMMUNITY_USER_INTEREST_BASE_FRAGMENT}
  ${COMMUNITY_USER_TAG_BASE_FRAGMENT}
  ${COMMUNITY_USER_URL_BASE_FRAGMENT}
  ${COMPANY_USER_ROLE_FULL_FRAGMENT}
  ${SUB_EDITION_USER_ROLE_FULL_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${ADDRESS_BASE_FRAGMENT}
  ${COUNTRY_BASE_FRAGMENT}
  ${SPEAKER_FULL_FRAGMENT}
`;
